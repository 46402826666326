import React, { useEffect, useRef, useState } from 'react'
import JWPlayer from '@jwplayer/jwplayer-react'
import { cn } from '../../lib/helpers'

import * as styles from './video.module.css'
import YouTube from 'react-youtube'

const Video = ({ videoId, playerOptions, type = 'standard', addClass = '', mute = 0, muteButton = false }) => {
  if (!videoId) return null

  const youtubePlayer = useRef()
  const [youtubePlayerPaused, setYoutubePlayerPaused] = useState(false)
  const [jwPlayerInstance, setJsPlayerInstance] = useState(false)
  const [isMuted, setIsMuted] = useState(!!mute)

  const jwPlayerMountedCallback = ({ player, id }) => {
    setJsPlayerInstance(player)
  }

  useEffect(() => {
    if (jwPlayerInstance) {
      const handleMuteChange = () => {
        setIsMuted(jwPlayerInstance.getMute())
      }

      jwPlayerInstance.on('mute', handleMuteChange)

      return () => {
        jwPlayerInstance.off('mute', handleMuteChange)
      }
    } else if (youtubePlayer.current && type === 'youtube') {
      let muteCheckInterval

      const ytPlayer = youtubePlayer.current.getInternalPlayer()

      const checkMuteState = () => {
        ytPlayer.isMuted().then((isMuted) => {
          setIsMuted(isMuted)
        })
      }

      // const onPlay = () => {
      // eslint-disable-next-line prefer-const
      muteCheckInterval = setInterval(checkMuteState, 500)
      // }

      // const onPauseOrEnd = () => {
      //   clearInterval(muteCheckInterval)
      // }
      //
      // ytPlayer.addEventListener('onStateChange', (event) => {
      //   if (event.data === 1) {
      //     onPlay()
      //   } else if (event.data === 2 || event.data === 0) {
      //     onPauseOrEnd()
      //   }
      // })

      return () => {
        clearInterval(muteCheckInterval)
      }
    }
  }, [jwPlayerInstance, youtubePlayer])

  const jwPlayers = {
    standard: 'NHPGCLVd',
    teaser: 'ef3wiA8P',
    hero: 'mh6rnlRF'
  }

  const youtubePlayerOptions = {
    playerVars: {
      autoplay: 1,
      mute: mute,
      ...playerOptions
    }
  }

  const _onEnd = (e) => {
    e.target.playVideo()
  }

  const toggleMute = () => {
    setIsMuted((prevMuted) => !prevMuted)

    if (type !== 'youtube' && jwPlayerInstance) {
      jwPlayerInstance.setMute(!isMuted)
    } else if (youtubePlayer.current) {
      const ytPlayer = youtubePlayer.current.getInternalPlayer()
      isMuted ? ytPlayer.unMute() : ytPlayer.mute()
    }
  }

  return (
    <>
      <div className={cn(styles.video, addClass ? styles[addClass] : null, type === 'youtube' ? styles.youtube : '')}>
        {type !== 'youtube' ? (
          <JWPlayer
            library={`https://cdn.jwplayer.com/libraries/${jwPlayers[type]}.js`}
            playlist={`https://cdn.jwplayer.com/v2/media/${videoId}`}
            didMountCallback={jwPlayerMountedCallback}
          />
        ) : (
          <>
            <YouTube
              ref={youtubePlayer} videoId={videoId} opts={youtubePlayerOptions}
              onPause={() => setYoutubePlayerPaused(true)}
              onPlay={() => setYoutubePlayerPaused(false)}
              onEnd={_onEnd}
            />
            <svg
              style={{ opacity: youtubePlayerPaused ? 1 : 0 }} width='138' height='138' viewBox='0 0 138 138'
              fill='none' xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='69' cy='69' r='67' stroke='white' strokeWidth='4' />
              <path d='M59 51L89 68.5L59 86L59 51Z' fill='white' />
            </svg>
          </>
        )}
      </div>
      {muteButton && (
        <button className={styles.muteButton} onClick={toggleMute}>
          <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect
              x='0.448593' y='0.448593' width='40.1031' height='40.1031' rx='20.0515' stroke='white'
              strokeWidth='0.897185'
            />
            <path d='M20.5 33.8228C27.8578 26.4649 27.8578 14.5355 20.5 7.17772' stroke='white' strokeWidth='1.79437' />
            <path
              d='M16.6934 30.0158C21.9489 24.7602 21.9489 16.2392 16.6934 10.9837' stroke='white'
              strokeWidth='1.79437'
            />
            <path
              d='M12.8867 26.2098C16.0401 23.0565 16.0401 17.9439 12.8867 14.7905' stroke='white'
              strokeWidth='1.79437'
            />
            {isMuted && (<line x1='0.646447' y1='40.8598' x2='40.6464' y2='0.859826' stroke='white' />)}
          </svg>
        </button>
      )}
    </>
  )
}

export default Video
